<template>
  <div class="error">
    <img :src="require('./static/image/404.png')" alt="" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.error {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>